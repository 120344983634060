"use client";

import { BookA, BookOpenText } from "lucide-react";

const links = [
  {
    name: "Бүлэг сэдэв",
    href: "/category",
    description: "Хүүхдүүдэд тохирох бүлэг сэдвүүдийг үзэх",
    icon: BookOpenText,
  },
  {
    name: "А-Я хайлт",
    href: "/alphabet-filtered",
    description: "Монгол цагаан толгойн үсгээр хайлт хийх",
    icon: BookA,
  },
  //   {
  //     name: "Оффис имэйл",
  //     description: "Байгууллагын хэрэгцээнд зориулсан имэйл үйлчилгээ",
  //     href: "/email/office",
  //     icon: Mail,
  //   },
  //   {
  //     name: "Дотоод хост",
  //     description: "Дотоодод байрлалтай Linux хостинг үйлчилгээ",
  //     href: "/hosting/internal",
  //     icon: HardDriveDownload,
  //     category: "host",
  //   },
];

export default function NotFound() {
  return (
    <div>
      <main className="mx-auto w-full h-screen flex flex-col justify-center max-w-7xl px-6 lg:px-8 pb-20">
        <div className="mx-auto mt-8 md:mt-20 max-w-2xl text-center sm:mt-24">
          <span className="text-xl font-bold leading-8 text-[#FF6D25]">
            404
          </span>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-[#31A3DE] sm:text-5xl font-gilroy">
            Хуудас олдсонгүй
          </h1>
          <span className="mt-4 text-base leading-7 text-[#00477C] sm:mt-6 sm:text-lg sm:leading-8">
            Уучлаарай, таны хайсан зүйл олдсонгүй.
          </span>
        </div>
        <div className="mx-auto mt-8 md:mt-16 flow-root max-w-lg sm:mt-20">
          <ul
            role="list"
            className="-mt-6 divide-y divide-slate-900/5 border-b border-slate-900/5"
          >
            {links.map((link, linkIdx) => (
              <a
                href={link.href}
                key={linkIdx}
                className="relative flex gap-x-6 py-6"
              >
                <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-slate-400/10">
                  <link.icon
                    className={`h-6 w-6 ${
                      link.featured ? "text-primary-600" : "text-[#40A4D8]"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-auto">
                  <h3
                    className={`font-semibold leading-6 text-sm sm:text-base ${
                      link.featured ? "gradient-text" : "text-[#12C96F]"
                    }`}
                  >
                    {link.name}
                  </h3>
                  <span className="mt-1 text-xs sm:text-sm leading-5 text-[#4c4d4f]">
                    {link.description}
                  </span>
                </div>
              </a>
            ))}
          </ul>
          <div className="mt-10 flex justify-center">
            <a
              href="/"
              className="text-sm font-semibold leading-6 text-[#00477C]"
            >
              <span aria-hidden="true" className="mr-1">
                &larr;
              </span>
              Нүүр хуудас
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}
